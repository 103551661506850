@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
body {
  width: 100%;
  height: 100vh;
  background-color: #111111;
  background-image: radial-gradient(circle at top right, rgba(121, 68, 154, 0.13),       transparent),
  radial-gradient(circle at 20% 80%, rgba(41, 196, 255, 0.13), transparent);
  font-size: 16px;
}

:root {
  --text-color: aliceblue;
}

canvas {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.canvas{
  background-color: #111111FF;
}

.center-container{
  width: 66vw;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 33%;
  position: fixed;
}

.center-principles{
  width: 70vw;
  left: 50%;
  transform: translate(-50%, 10px);
  position: relative;
}

.mobile-center-container{
  width: 90vw;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 30%;
  position: absolute;
}

.principle-container {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;

  animation-duration: 0.6s;
  animation-name: in;
  animation-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

.animate{
  animation-duration: 0.6s;
  animation-name: out;
  animation-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

@keyframes out {
  from {opacity: 1;}
  to {opacity: 0;}
}

@keyframes in {
  from {opacity: 0;}
  to {opacity: 1;}
}

.button-container {
  /*sorting*/
  display: flex;
  flex-direction: column;

  /*positions*/
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobile-button-container{
  display: flex;
  flex-direction: column;

  z-index: 10;
  position: fixed;
  bottom: 10%;
  width: 100%;
}

.share-container{
  display: flex;
}

.firstTimeModal{
  color: var(--text-color);
  border-radius: 16px;
  position: absolute;
  background-color: #088888;
  width: 50vw;
  transform: translate(21vw, 50%);
  padding: 20px;
  z-index: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Ubuntu", sans-serif;
}


.button{
  /*colours*/
  background-color: #077777;
  color: var(--text-color);

  /*shape*/
  margin: 5px;
  width: 15vw;
  border: 15px #077777;
  padding: 15px 0;
  border-radius: 8px;
  transition: background-color 0.3s ease;

  /*text props*/
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.2rem;
  cursor: pointer;
}

.button:hover {
  background-color: #088888;
}

.action {
  width: auto;
}

.copy-message {
  background-color: #099966;
  color: var(--text-color);
}

.copy-message:hover {
  background-color: #099966;
  color: var(--text-color);
}

.social{
  width: 100%;
  float: left;
}

h1{
  font-family: "Ubuntu", sans-serif;
  color: var(--text-color);
  margin: 10px 0;
  text-align: center;
  font-size: 2rem;
}

.description, a{
  font-family: "Rubik", sans-serif;
  color: var(--text-color);
}

.mobile-description{
  font-size: 1rem;
}

.desktop-description{
  font-size: 1.4rem;
}

.source{
  font-family: "Rubik", sans-serif;
  color: var(--text-color);
  font-size: 1rem;
  font-style: italic;
  margin: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* Hide scrollbars */
  display: block;
}

.spacer{
  height: 50px;
  color: var(--text-color);
  align-content: end;
  text-align: center;
}

footer{
  display: flex;
  flex-direction: row;
  position: fixed;
  justify-content: center;
  gap: 5px;
  bottom: 0;
  width: 100vw;
}

footer p, footer p a{
  color: var(--text-color);
  font-size: .8rem;
  font-family: "Rubik", sans-serif;
}
